import warning from '@amo/core/assets/images/icons/warning.svg';
import { BulleAide, QuestionLabel, SimpleTitle } from '@amo/core/components/containers';
import { Radio, Text } from '@amo/core/components/forms';
import { normalizeNumber } from '@amo/core/utils/normalize';
import { required } from '@amo/core/utils/validateField';
import { Field } from 'redux-form';
import QuestionContentContainer from '../../QuestionContent/QuestionContentContainer';

const Besoins = (props) => {
    const {
        assistance,
        montantAccessoires,
        typeVehicule,
        valid,
        handleSubmit,
        nextStep,
        fillLoading,
        previousStep,
        unsetValue,
        apporteur1,
    } = props;

    return (
        <div>
            <SimpleTitle>Vos besoins</SimpleTitle>

            <QuestionContentContainer name={'DemandeTarif[DevoirConseil][DevoirVol]'}>
                <QuestionLabel>
                    Voici quelques questions pour mieux connaître vos besoins en assurance et pouvoir vous proposer nos
                    meilleures offres.
                </QuestionLabel>
                <QuestionLabel className={'f-14 mt-5'} required>
                    Souhaitez-vous assurer votre véhicule en cas de vol ou d’incendie&nbsp;?&nbsp;
                </QuestionLabel>
                <div className={'row'}>
                    <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                        <Field
                            component={Radio}
                            name={'DemandeTarif[DevoirConseil][DevoirVol]'}
                            value={'true'}
                            type={'radio'}
                            label={'Oui'}
                            validate={required}
                            className={'small'}
                        />
                    </div>
                    <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                        <Field
                            component={Radio}
                            name={'DemandeTarif[DevoirConseil][DevoirVol]'}
                            value={'false'}
                            type={'radio'}
                            label={'Non'}
                            validate={required}
                            className={'small'}
                        />
                    </div>
                </div>
            </QuestionContentContainer>

            <QuestionContentContainer name={'DemandeTarif[DevoirConseil][DevoirDommage]'}>
                <QuestionLabel className={'f-14 mt-5'} required>
                    Souhaitez-vous assurer votre véhicule en cas d'accident matériel responsable (Tous
                    risques)&nbsp;?&nbsp;
                </QuestionLabel>
                <div className={'row'}>
                    <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                        <Field
                            component={Radio}
                            name={'DemandeTarif[DevoirConseil][DevoirDommage]'}
                            value={'true'}
                            type={'radio'}
                            label={'Oui'}
                            validate={required}
                            className={'small'}
                        />
                    </div>

                    <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                        <Field
                            component={Radio}
                            name={'DemandeTarif[DevoirConseil][DevoirDommage]'}
                            value={'false'}
                            type={'radio'}
                            label={'Non'}
                            validate={required}
                            className={'small'}
                        />
                    </div>
                </div>
            </QuestionContentContainer>

            <QuestionContentContainer name={'DemandeTarif[DevoirConseil][DevoirASS2]'}>
                <QuestionLabel className={'f-14 mt-5'} required>
                    Souhaitez vous pouvoir être pris en charge en cas de panne ou de crevaison&nbsp;?&nbsp;
                </QuestionLabel>
                <div className={'row'}>
                    <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                        <Field
                            component={Radio}
                            name={'DemandeTarif[DevoirConseil][DevoirASS2]'}
                            value={'true'}
                            type={'radio'}
                            label={'Oui'}
                            validate={required}
                            className={'small'}
                        />
                    </div>
                    <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                        <Field
                            component={Radio}
                            name={'DemandeTarif[DevoirConseil][DevoirASS2]'}
                            value={'false'}
                            type={'radio'}
                            label={'Non'}
                            validate={required}
                            className={'small'}
                        />
                    </div>
                </div>
            </QuestionContentContainer>

            {assistance === 'true' &&
                (typeVehicule === 'moto' || typeVehicule === 'scooter') &&
                apporteur1.codeBelair !== 809466 && (
                    <QuestionContentContainer name={'DemandeTarif[DevoirConseil][DevoirASS3]'}>
                        <QuestionLabel className={'f-14 mt-5'} required>
                            Si votre véhicule est immobilisé ou volé, souhaitez-vous un véhicule de remplacement
                            &nbsp;?&nbsp;
                        </QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[DevoirConseil][DevoirASS3]'}
                                    value={'true'}
                                    type={'radio'}
                                    label={'Oui'}
                                    validate={required}
                                    className={'small'}
                                />
                            </div>
                            <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[DevoirConseil][DevoirASS3]'}
                                    value={'false'}
                                    type={'radio'}
                                    label={'Non'}
                                    validate={required}
                                    className={'small'}
                                />
                            </div>
                        </div>
                    </QuestionContentContainer>
                )}

            <QuestionContentContainer name={'DemandeTarif[DevoirConseil][DevoirIC]'}>
                <QuestionLabel className={'f-14 mt-5'} required>
                    Souhaitez-vous être indemnisé(e) en cas d’accident responsable qui pourrait vous provoquer de graves
                    blessures, voire même un décès&nbsp;?&nbsp;
                </QuestionLabel>
                <div className={'row'}>
                    <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                        <Field
                            component={Radio}
                            name={'DemandeTarif[DevoirConseil][DevoirIC]'}
                            value={'3'}
                            type={'radio'}
                            label={'Oui'}
                            validate={required}
                            className={'small'}
                        />
                    </div>

                    <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                        <Field
                            component={Radio}
                            name={'DemandeTarif[DevoirConseil][DevoirIC]'}
                            value={'0'}
                            type={'radio'}
                            label={'Non'}
                            validate={required}
                            className={'small'}
                        />
                    </div>
                </div>
            </QuestionContentContainer>

            <BulleAide
                icon={warning}
                title={`<b>Le saviez-vous ?</b>`}
                text={`<p>Si vous êtes victime et blessé(e) suite à un accident causé par un autre véhicule, vos blessures seront indemnisées par son assureur.</p><p>Mais si vous êtes le responsable ou que vous avez provoqué seul cet accident, les impacts financiers d'une invalidité ou même d'un décès peuvent être importants pour vous et vos proches.</p><p>Vous pouvez vous assurer pour faire face à ces conséquences financières.</p>`}
                className={'col-lg-12 col-md-12 f-14 mt-4'}
            />

            {apporteur1.codeBelair !== 809466 && (
                <>
                    <QuestionContentContainer name={'DemandeTarif[DevoirConseil][DevoirMontantACCESSEQP]'}>
                        <QuestionLabel className={'f-14 mt-5'} required>
                            A combien estimez-vous le montant des accessoires de votre véhicule (pot d'échappement,
                            selle confort, poignées chauffantes, top case,..) et de vos équipements de protection
                            (casque, gants, blouson, pantalon, bottes, gilet airbag...)&nbsp;?&nbsp;
                        </QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-6 col-md-6 mt-2 mt-md-2'}>
                                <Field
                                    component={Text}
                                    name={'DemandeTarif[DevoirConseil][DevoirMontantACCESSEQP]'}
                                    icon={'payment1'}
                                    normalize={normalizeNumber}
                                    onChange={() => unsetValue('DemandeTarif[DevoirConseil][DevoirACCESSEQP]')}
                                />
                            </div>
                        </div>
                    </QuestionContentContainer>

                    {Number(montantAccessoires) > 0 && (
                        <QuestionContentContainer name={'DemandeTarif[DevoirConseil][DevoirACCESSEQP]'}>
                            <QuestionLabel className={'f-14 mt-5'} required>
                                Souhaitez-vous les assurer en cas de vol ou de dommages&nbsp;?&nbsp;
                            </QuestionLabel>
                            <div className={'row'}>
                                <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                    <Field
                                        component={Radio}
                                        name={'DemandeTarif[DevoirConseil][DevoirACCESSEQP]'}
                                        value={'true'}
                                        type={'radio'}
                                        label={'Oui'}
                                        validate={required}
                                        className={'small'}
                                    />
                                </div>

                                <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                    <Field
                                        component={Radio}
                                        name={'DemandeTarif[DevoirConseil][DevoirACCESSEQP]'}
                                        value={'false'}
                                        type={'radio'}
                                        label={'Non'}
                                        validate={required}
                                        className={'small'}
                                    />
                                </div>
                            </div>
                        </QuestionContentContainer>
                    )}

                    {typeVehicule !== '50' && (
                        <QuestionContentContainer name={'DemandeTarif[DevoirConseil][DevoirRAFRA]'}>
                            <QuestionLabel className={'f-14 mt-5'} required>
                                En cas de vol, incendie ou dommages du véhicule, une somme financière reste à votre
                                charge (franchise). Seriez-vous intéressé(e) pour ne plus avoir cette franchise à payer
                                (nous proposons le rachat de franchise sur mesure)&nbsp;?&nbsp;
                            </QuestionLabel>
                            <div className={'row'}>
                                <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                    <Field
                                        component={Radio}
                                        name={'DemandeTarif[DevoirConseil][DevoirRAFRA]'}
                                        value={'true'}
                                        type={'radio'}
                                        label={'Oui'}
                                        validate={required}
                                        className={'small'}
                                    />
                                </div>

                                <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                    <Field
                                        component={Radio}
                                        name={'DemandeTarif[DevoirConseil][DevoirRAFRA]'}
                                        value={'false'}
                                        type={'radio'}
                                        label={'Non'}
                                        validate={required}
                                        className={'small'}
                                    />
                                </div>
                            </div>
                        </QuestionContentContainer>
                    )}
                </>
            )}

            <div className={'submit-row mt-5'}>
                <button
                    className={`${!valid && 'disabled'} btn btn-primary float-right medium-rounded mb-2 order-lg-1`}
                    onClick={valid ? handleSubmit(nextStep) : null}
                    data-id="btn-next"
                >
                    {fillLoading ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    ) : (
                        'Continuer'
                    )}
                </button>

                <button className={`btn btn-outline-primary medium-rounded order-lg-0`} onClick={() => previousStep()}>
                    Retour
                </button>
            </div>
            <div className="row">
                <p className={'mt-4 text-grey'}>
                    <small>Merci de vérifier si tous les champs du formulaire ont bien été saisis.</small>
                </p>
            </div>
        </div>
    );
};

export default Besoins;
