import {QuestionLabel, Card, TableCollapse, Icons, SimpleTitle} from '@amo/core/components/containers';
import {DateTimePicker, PostalCodeCity, Radio, Text, Select, Checkbox} from '@amo/core/components/forms';
import { normalizeImmatriculation, normalizeNumMarquage } from '@amo/core/utils/normalize';
import { checkIban, getCompanys } from '@amo/core/utils/requestsApi.js';
import {
    excludeCodePostal,
    maxDate30Days,
    maxDate33Days,
    minDateHourToday,
    minLentghImmatriculation,
    required,
} from '@amo/core/utils/validateField';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Field } from 'redux-form';
import TiersPayeurContainer from './TiersPayeur/TiersPayeurContainer';
import questions from "formulaire-courtier/src/utils/json/questions.json";

const Recapitulatif = (props) => {
    const {
        devisHamon,
        conducteur,
        addressConducteur,
        crmAuto,
        crmMoto,
        marque,
        modele,
        version,
        dateMec,
        neConnaitPasNumeroContratActuel,
        usage,
        formule,
        fractionnement,
        changeValue,
        prelevement_auto,
        postalCodeCity,
        VehiculeImmatricule,
        permis,
        nomTitulaire,
        nomPrenom,
        adresseIban,
        cpIban,
        villeIban,
        dateHeureEffet,
        ibanIsCheck,
        valid,
        handleSubmit,
        postPaySubmit,
        payLoaded,
        previousStep,
        nbMoisAssuranceMoto,
        typeVehicule,
        souscripteurIsSociety,
        idRealisateur,
        immatriculationAlreadySet,
        searchBy
    } = props;
    const [companys, setCompanys] = useState([]);
    const [errorIban, setErrorIban] = useState(null);
    const [loadingIban, setLoadingIban] = useState(false);
    const inputIban = useRef('');
    const [immatDisabled, setImmatDisabled] = useState(immatriculationAlreadySet && searchBy === "immatriculation");

    useEffect(() => {
        (async function fetchCompanys() {
            let listCompanys = await getCompanys();
            setCompanys(listCompanys);
        })();

        if (fractionnement === 'M') {
            changeValue('DemandeContrat[ModePaiementTerme]', 'P');
        }

        changeValue('DemandeContrat[NomTitulaire]', nomPrenom);
        changeValue('DemandeContrat[adrIBAN]', adresseIban);
        changeValue('DemandeContrat[cpIBAN]', cpIban);
        changeValue('DemandeContrat[villeIBAN]', villeIban);
        changeValue('postalCodeCity-villeIban', true);
        changeValue('DemandeContrat[TiersPayeur]', {});

        if (devisHamon && devisHamon === '1'){
            changeValue('DemandeContrat[DateHeureEffet]', moment().add(33, 'days'));
        } else {
            changeValue('DemandeContrat[DateHeureEffet]', moment().add(15, 'minutes'));
        }

    }, []);

    const changeImmatriculation = (value) => {
        if (value === 'VIN') {
            changeValue('DemandeTarif[Vehicule][VehiculeImmatricule]', 0);
        } else {
            changeValue('DemandeTarif[Vehicule][VehiculeImmatricule]', 1);
        }
    };
    const verificationTitulaire = (value) => {
        if (value && _.toUpper(nomPrenom) === _.toUpper(value)) {
            changeValue('DemandeContrat[TiersPayeur]', {});
        }
    };

    const fetchCheckIban = async (value) => {
        setLoadingIban(true);
        let iban = await checkIban(value);
        setLoadingIban(false);
        changeValue('DemandeContrat[BIC]', iban.bank_data.bic);

        /* Vérifie si le format de l'iban est correct */
        const codeValidationsSuccess = ['001', '002', '003', '004', '005', '006', '007'];
        const fieldValidations = ['account', 'country_support', 'iban', 'length', 'structure'];
        let error = null;

        _.forEach(fieldValidations, (field) => {
            if (!_.includes(codeValidationsSuccess, iban.validations[field].code)) {
                error = iban.validations[field].message;
                return false;
            }
        });

        /* Vérifie si l'iban accepte les prélèvement */
        if (iban.sepa_data.SDD === 'NO' || iban.sepa_data.SCT === 'NO') {
            return setErrorIban("L'IBAN renseignée n'autorise pas les prélèvements bancaires");
        }

        return error ? setErrorIban(`Le format de l'IBAN n'est pas valide. <br /> ${error}`) : setErrorIban(null);
    };

    return (
        <div>
            <SimpleTitle>Votre récapitulatif</SimpleTitle>

            <div className={'row mt-5'}>
                <div className={'col-12 col-md-6 text-initial'}>
                    <QuestionLabel>Vos informations</QuestionLabel>
                    <Card icon={'beneficiaire'} title={conducteur}>
                        <p className={'mb-2'}>
                            Adresse
                            <br />
                            <span className={'fw-bold'}>{addressConducteur}</span>
                        </p>

                        {crmAuto && (
                            <p className={'mb-2'}>
                                {crmAuto >= 1 ? 'Bonus' : 'Malus'} Auto:{' '}
                                <span className={'fw-bold'}>{_.round(crmAuto, 2)}</span>
                            </p>
                        )}

                        {crmMoto && (
                            <p className={'mb-3'}>
                                {crmMoto >= 1 ? 'Bonus' : 'Malus'} 2-roues:{' '}
                                <span className={'fw-bold'}>{_.round(crmMoto, 2)}</span>
                            </p>
                        )}

                        {_.find(permis, function (p) {
                            return p && p.TypePermis;
                        }) !== undefined && (
                            <div className={'mb-3'}>
                                <Field
                                    component={Text}
                                    name={'DemandeTarif[ListePersonnes][0][NumPermis]'}
                                    label={
                                        <span>
                                            Numéro du permis&nbsp;<sup className={'text-error'}>*</sup>
                                        </span>
                                    }
                                    normalize={(v) => _.trim(v.toUpperCase())}
                                    validate={required}
                                />
                            </div>
                        )}

                        {VehiculeImmatricule === '0' ? (
                            <Field
                                component={Text}
                                name={'DemandeTarif[Vehicule][VIN]'}
                                label={
                                    <span>
                                        Numéro de série&nbsp;<sup className={'text-error'}>*</sup>
                                    </span>
                                }
                                normalize={normalizeNumMarquage}
                                validate={[required]}
                                onChange={() => changeImmatriculation('VIN')}
                            />
                        ) : (
                            <Field
                                component={Text}
                                name={'DemandeTarif[Vehicule][Immatriculation]'}
                                label={
                                    <span>
                                        Numéro d'immatriculation&nbsp;<sup className={'text-error'}>*</sup>
                                    </span>
                                }
                                normalize={normalizeImmatriculation}
                                validate={[required, minLentghImmatriculation]}
                                onChange={() => changeImmatriculation('Immat')}
                                disabled={immatDisabled}
                            />
                        )}
                    </Card>
                </div>

                <div className={'col-12 col-md-6 text-initial'}>
                    <div className={'h-100 d-flex flex-column '}>
                        <QuestionLabel>Votre véhicule</QuestionLabel>
                        <Card icon={'moto'} title={'Votre véhicule'} className={'flex-fill'}>
                            <div className={'text-primary f-14 font-weight-bold mb-2'}>{marque}</div>

                            <p className={'mb-2'}>
                                {modele} {version}
                            </p>

                            <p className={'mb-2'}>
                                Mise en circulation
                                <br />
                                <span className={'fw-bold'}>{dateMec}</span>
                            </p>

                            <p className={'mb-2'}>
                                Usage
                                <br />
                                <span className={'fw-bold'}>{usage}</span>
                            </p>
                        </Card>
                    </div>
                </div>
            </div>

            <QuestionLabel>Votre récapitulatif</QuestionLabel>
            <QuestionLabel className={'f-14 my-4'}>
                Vous avez choisi la formule : <span className={'text-primary-light'}>{formule?.LibelleFormule}</span>
            </QuestionLabel>

            <div className={'row my-4'}>
                <div className={'col-12 col-md-12'}>
                    <div className={`scrollingTable`}>
                        <div className="table-responsive">
                            <table className={"table mb-0"}>
                                <thead>
                                <tr>
                                    <th scope="col" className={'bg-secondary-light text-initial border-bottom-0 w-50 fw-bold '}>
                                        Vos garanties
                                    </th>
                                    <th scope="col" className={'bg-primary text-white border-bottom-0 w-50 fw-bold'}>
                                        <p className={'mb-0 d-flex justify-content-between align-items-center'}>
                                            <span>{formule?.LibelleFormule}</span>
                                            <span className={'f-16 font-weight-normal'}>{fractionnement === 'M' ? `${_.round(formule.PrimeMensuellePromo, 2)}/mois` : `${_.round(formule.ComptantAnnuelPromo, 2)}/an`}</span>
                                        </p>
                                    </th>
                                </tr>
                                </thead>
                                <tbody className={'text-center'}>
                                {
                                    _.map(formule.ListeGaranties, (g, i) => {
                                        return g.Souscrite && <tr key={i}>
                                            <th scope="row" className={'f-14 font-weight-normal text-initial text-left'}>
                                                <TableCollapse
                                                    title={
                                                        <div>
                                                            {g.LibelleGarantie}
                                                        </div>
                                                    }
                                                >
                                                    { g.texteDeroulant && <p>{g.texteDeroulant}</p> }
                                                </TableCollapse>
                                            </th>
                                            <td className={'align-middle bg-primary'}>
                                                <Icons className={`text-primary-light p-2 border full-rounded border-white text-white`} fontSize={5} icon={'Shape'}/>
                                            </td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <p className={'f-14 mt-4'} required>
                Date et heure d'effet&nbsp;
            </p>
            <div className={'row'}>
                <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                    <Field
                        component={DateTimePicker}
                        changeValue={changeValue}
                        name={'DemandeContrat[DateHeureEffet]'}
                        label={'JJ/MM/AAAA HH:MM'}
                        validate={
                            devisHamon && devisHamon === '1'
                                ? [minDateHourToday, maxDate33Days]
                                : [minDateHourToday, maxDate30Days]
                        }
                        minDate={
                            devisHamon && devisHamon === '1' ? new Date(moment().add(33, 'days')) : new Date(moment())
                        }
                        maxDate={
                            devisHamon && devisHamon === '1'
                                ? new Date(moment().add(33, 'days'))
                                : new Date(moment().add(30, 'days'))
                        }
                        disabled={devisHamon && devisHamon === '1'}
                    />
                </div>
            </div>

            <hr />

            <QuestionLabel>Paiement</QuestionLabel>
            {fractionnement === 'M' ? (
                <>
                    <p className={'QuestionLabel f-14 mt-4'}>
                        Montant à régler (TTC)&nbsp;: <strong>{_.round(formule.ComptantMensuelPromo, 2)}€</strong>{' '}
                        correspondant à vos deux premières mensualités.
                    </p>
                    <p className={'QuestionLabel f-14 mt-4'}>
                        Vos prochains prélèvements seront de <strong>{_.round(formule.PrimeMensuellePromo, 2)}€</strong>
                    </p>
                </>
            ) : (
                <>
                    <p className={'QuestionLabel f-14 mt-4'}>
                        Montant à régler (TTC)&nbsp;: <strong>{_.round(formule.ComptantAnnuelPromo, 2)}€</strong>
                    </p>
                </>
            )}

            {[542879, 588216, 545721].includes(idRealisateur) && (
                <>
                <QuestionLabel>Mode de paiement</QuestionLabel>

                <div className={'row'}>
                    <div className={'col-12 col-md-6 mt-2 mt-md-2'}>
                        <Field
                            component={Radio}
                            name={'DemandeContrat[ModePaiementComptant]'}
                            value={'M'}
                            type={'radio'}
                            label={'Envoie du lien de paiement'}
                            validate={required}
                            className={'small'}
                        />
                    </div>
                    <div className={'col-12 col-md-6 mt-2 mt-md-2'}>
                        <Field
                            component={Radio}
                            name={'DemandeContrat[ModePaiementComptant]'}
                            value={'C'}
                            type={'radio'}
                            label={'Cheque'}
                            validate={required}
                            className={'small'}
                        />
                    </div>
                </div>
                </>
            )}


            {(nbMoisAssuranceMoto > 11 || _.includes(['50', 'quad', 'verte'], typeVehicule)) &&
                !souscripteurIsSociety && (
                    <>
                        <hr />

                        <QuestionLabel>La loi Hamon</QuestionLabel>

                        <p className={'f-14 mt-4'} required>
                            Souhaitez-vous qu’APRIL Moto s’occupe de résilier le contrat de votre précédente
                            assurance&nbsp;?&nbsp;
                        </p>

                        <div className={'row'}>
                            <div className={'col-12 col-md-4 mt-2 mt-md-2'} data-name="DemandeContrat[DevisHamon]">
                                <Field
                                    component={Radio}
                                    name={'DemandeContrat[DevisHamon]'}
                                    value={'1'}
                                    type={'radio'}
                                    label={'Oui'}
                                    onChange={() =>
                                        changeValue('DemandeContrat[DateHeureEffet]', moment().add(33, 'days'))
                                    }
                                    validate={required}
                                    className={'small'}
                                />
                            </div>
                            <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeContrat[DevisHamon]'}
                                    value={'0'}
                                    type={'radio'}
                                    label={'Non'}
                                    onChange={() => {
                                        if (
                                            dateHeureEffet &&
                                            moment.isMoment(dateHeureEffet) &&
                                            moment(dateHeureEffet)._isValid &&
                                            dateHeureEffet.diff(moment().endOf('day'), 'days') >= 30
                                        ) {
                                            changeValue('DemandeContrat[DateHeureEffet]', moment());
                                        }
                                    }}
                                    validate={required}
                                    className={'small'}
                                />
                            </div>
                        </div>
                    </>
                )}

            {devisHamon && devisHamon === '1' && (
                <div>
                    <p className={'f-14 mt-4'}>
                        Quel est votre assureur actuel ?&nbsp;<small className={'text-error text-danger f-16'}>*</small>
                    </p>
                    <div className={'row'}>
                    <div
                            className={'col-12 col-md-4 mt-2 mt-md-2'}
                            data-name="DemandeContrat[DevisHamonAssureurActuel]"
                        >
                            <Field
                                component={Select}
                                name={'DemandeContrat[DevisHamonAssureurActuel]'}
                                validate={required}
                                loading={_.isEmpty(companys)}
                            >
                                <option value={''}>Sélectionnez...</option>
                                {companys.map((company, index) => {
                                    if (company.code !== "" && company.libelle !== "") {
                                        return <option key={index} value={`${company.code}|${company.libelle}`}>
                                            {company.libelle}
                                        </option>
                                    }
                                })}
                            </Field>
                        </div>
                    </div>

                    <p className={'f-14 mt-4'}>
                        Quel est votre numéro de contrat actuel&nbsp;?{!neConnaitPasNumeroContratActuel && <small
                        className={'text-error text-danger f-16'}>*</small>}
                    </p>
                    <div className={'row'}>
                    <div
                            className={'col-12 col-md-4 mt-2 mt-md-2'}
                            data-name="DemandeContrat[DevisHamonContratActuel]"
                        >
                            <Field
                                component={Text} name={'DemandeContrat[DevisHamonContratActuel]'}
                                validate={!neConnaitPasNumeroContratActuel ? required : null}
                                disabled={neConnaitPasNumeroContratActuel}
                            />
                        </div>
                    </div>
                    <Field
                        name="dont-know-contract-number"
                        component={Checkbox}
                        type={"checkbox"}
                        className={"ml-2"}
                    >
                        <div className={'f-14 mx-2 mt-2 cursor-pointer'}>Le client ne connait pas son numéro de contrat actuel</div>
                    </Field>
                </div>
            )}

            <QuestionLabel>Prélèvement</QuestionLabel>

            {fractionnement !== 'M' && (
                <>
                    <p className={'f-14 mt-4'} required>
                        Souhaitez-vous opter pour le prélèvement automatique&nbsp;?&nbsp;
                    </p>

                    <div className={'row'}>
                        <div className={'col-12 col-md-4 mt-2 mt-md-2'} data-name="prelevement_auto">
                            <Field
                                component={Radio}
                                name={'prelevement_auto'}
                                value={'1'}
                                type={'radio'}
                                label={'Oui'}
                                validate={required}
                                onChange={() => changeValue('DemandeContrat[ModePaiementTerme]', 'P')}
                                className={'small'}
                            />
                        </div>
                        <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'prelevement_auto'}
                                value={'0'}
                                type={'radio'}
                                label={'Non'}
                                validate={required}
                                onChange={() => {
                                    changeValue('DemandeContrat[ModePaiementTerme]', 'B');
                                    changeValue('DemandeContrat[TiersPayeur]', {});
                                }}
                                className={'small'}
                            />
                        </div>
                    </div>
                </>
            )}

            {(prelevement_auto && prelevement_auto === '1') || fractionnement === 'M' ? (
                <>
                    <p className={'f-14 mt-4'} required>
                        Nom et prénom du titulaire du compte&nbsp;
                    </p>
                    <div className={'row'}>
                        <div className={'col-12 col-md-8 mt-2 mt-md-2'}>
                            <Field
                                component={Text}
                                name={'DemandeContrat[NomTitulaire]'}
                                normalize={(v) => v.toUpperCase()}
                                validate={required}
                                onChange={(e) => verificationTitulaire(e.target.value)}
                            />
                        </div>
                    </div>

                    <p className={'f-14 mt-4'} required>
                        Adresse&nbsp;
                    </p>
                    <div className={'row'}>
                        <div className={'col-12 col-md-8 mt-2 mt-md-2'}>
                            <Field
                                component={Text}
                                name={'DemandeContrat[adrIBAN]'}
                                normalize={(v) => v.toUpperCase()}
                                validate={required}
                            />
                        </div>
                    </div>

                    <p className={'f-14 mt-4'} required>
                        Ville / Code postal&nbsp;
                    </p>
                    <div className={'row'}>
                        <div className={'col-12 col-md-8 mt-2 mt-md-2'} data-name="DemandeContrat[villeIBAN]">
                            <PostalCodeCity
                                icon={'pin'}
                                id={'villeIban'}
                                nameVille={'DemandeContrat[villeIBAN]'}
                                changeValue={changeValue}
                                nameCodePostal={'DemandeContrat[cpIBAN]'}
                                validate={[excludeCodePostal]}
                                defaultValue={postalCodeCity}
                                required
                            />
                        </div>
                    </div>

                    <p className={'f-14 mt-4'}>IBAN</p>
                    <div className={'row'}>
                        <div className={'col-12 col-md-8 mt-2 mt-md-2'} data-name="DemandeContrat[IBAN]">
                            <Field
                                component={Text}
                                name={'DemandeContrat[IBAN]'}
                                ref={inputIban}
                                normalize={(v) => v.toUpperCase()}
                            />

                            {errorIban && (
                                <div className={'alert-danger alert'} dangerouslySetInnerHTML={{ __html: errorIban }} />
                            )}

                            {ibanIsCheck && !errorIban && (
                                <div className={'alert-success alert mb-0'}>L'IBAN est valide</div>
                            )}

                            <span
                                className={'btn btn-primary mt-3 w-100'}
                                onClick={loadingIban ? null : () => fetchCheckIban(inputIban.current.value)}
                            >
                                {loadingIban ? (
                                    <div className="spinner-border" role="status" style={{ width: 19, height: 19 }}>
                                        <span className="sr-only" />
                                    </div>
                                ) : (
                                    "Je vérifie l'IBAN"
                                )}
                            </span>

                            <div className={'bg-warning p-4 rounded text-left mt-5 f-14'}>
                                <p className={'mb-0'}>
                                    <strong>Attention ! </strong>
                                    Aucun mandat SEPA ne sera généré si le champ IBAN n'est pas rempli avant de valider
                                    le contrat. Il faudra alors que le client nous transmette le RIB.
                                </p>
                            </div>
                        </div>
                    </div>

                    {ibanIsCheck && (
                        <>
                            <p className={'f-14 mt-4'}>Code BIC&nbsp;</p>
                            <div className={'row'}>
                                <div className={'col-12 col-md-8 mt-2 mt-md-2'}>
                                    <Field
                                        component={Text}
                                        name={'DemandeContrat[BIC]'}
                                        normalize={(v) => v.toUpperCase()}
                                        disabled
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    {_.toUpper(nomPrenom) !== _.toUpper(nomTitulaire) && <TiersPayeurContainer {...props} />}
                </>
            ) : (
                ''
            )}

            <div className={'submit-row mt-5'}>
                <button
                    className={`${!valid && 'disabled'} btn btn-primary medium-rounded float-right mb-2 order-lg-1`}
                    data-id="btn-souscription"
                    onClick={valid ? handleSubmit(postPaySubmit) : null}
                >
                    {!!payLoaded ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    ) : (
                        'Je finalise la souscription immédiatement'
                    )}
                </button>

                <button className={`btn btn-outline-primary medium-rounded order-lg-0`} onClick={() => previousStep()}>
                    Retour
                </button>
            </div>
        </div>
    );
};

export default Recapitulatif;
