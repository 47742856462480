import { useState } from 'react';
import { Field } from 'redux-form';
import moment from 'moment';
import { maxDateToday, requiredDateWithError, minDate24Mois, minDate36Mois } from "@amo/core/utils/index.js";
import { DateField } from "@amo/core/components/forms/index.js";
import { LinkButton } from "@amo/core/components/containers/index.js";
import { BulleAide } from "@amo/core/components/containers";

const Calculette = ({ antecedentsSur3ans, changeValue }) => {
    const [periods, setPeriods] = useState([{ startDate: null, endDate: null }]);
    const [totalMonths, setTotalMonths] = useState(0);
    const [totalDays, setTotalDays] = useState(0);
    const [dateWarning, setDateWarning] = useState("");
    const [duplicateStartDateWarning, setDuplicateStartDateWarning] = useState(false);

    const addPeriod = () => {
        setPeriods([...periods, { startDate: null, endDate: null }]);
    };

    const calculateTotalMonths = () => {
        let totalMonths = 0;
        let totalDays = 0;
        const limitDate = antecedentsSur3ans ? moment().subtract(3, 'years') : moment().subtract(2, 'years');

        // Reset warnings
        setDateWarning("");
        setDuplicateStartDateWarning(false);

        // Adjust periods based on limit date
        const newPeriods = periods.map((period, index) => {
            let newStartDate = period.startDate;
            if (period.startDate) {
                const start = moment(period.startDate);
                if (start.isBefore(limitDate)) {
                    newStartDate = limitDate.toDate();
                    setDateWarning(`La date minimum est le ${limitDate.format('DD/MM/YYYY')}. La date de début a été ajustée.`);
                    changeValue(`startDate_${index}`, limitDate);
                }
                return { ...period, startDate: newStartDate };
            }
            return period;
        });

        // Update periods with any adjusted dates
        setPeriods(newPeriods);

        // Check for duplicate start dates within newPeriods
        const startDateCounts = newPeriods.reduce((acc, period) => {
            if (period.startDate) {
                const formattedStart = moment(period.startDate).format('YYYY-MM-DD');
                acc[formattedStart] = (acc[formattedStart] || 0) + 1;
            }
            return acc;
        }, {});

        if (Object.values(startDateCounts).some(count => count > 1)) {
            setDuplicateStartDateWarning(true);
            return; // Stop calculation if duplicates are found
        }

        // Calculate total months and days if no duplicates are found
        newPeriods.forEach(period => {
            if (period.startDate && period.endDate) {
                const start = moment(period.startDate);
                const end = moment(period.endDate);

                const months = end.diff(start, 'months');
                start.add(months, 'months');
                const days = end.diff(start, 'days');

                totalMonths += months;
                totalDays += days;
            }
        });

        // Adjust days into months if needed
        if (totalDays >= 30) {
            totalMonths += Math.floor(totalDays / 30);
            totalDays = totalDays % 30;
        }

        // Update totalMonths and totalDays
        setTotalMonths(totalMonths);
        setTotalDays(totalDays);
    };


    const resetCalculator = () => {
        setPeriods([{ startDate: null, endDate: null }]);
        setTotalMonths(0);
        setTotalDays(0);
        setDateWarning("");
        setDuplicateStartDateWarning(false);
    };

    return (
        <div>
            <hr/>
            <h3 className={'question-label mt-0'}>Calcul des périodes d'assurance</h3>
            {periods.map((period, index) => (
                <div className={'row'} key={index}>
                    <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                        <Field
                            component={DateField}
                            name={`startDate_${index}`}
                            label={'Date de début'}
                            validate={[
                                requiredDateWithError,
                                maxDateToday
                            ]}
                            maxDate={new Date()}
                            onChange={(event, newValue) => {
                                const newPeriods = [...periods];
                                newPeriods[index].startDate = newValue;
                                setPeriods(newPeriods);
                                setDateWarning("");
                            }}
                        />
                    </div>
                    <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                        <Field
                            component={DateField}
                            name={`endDate_${index}`}
                            label={'Date de fin'}
                            validate={[requiredDateWithError, maxDateToday]}
                            maxDate={new Date()}
                            onChange={(event, newValue) => {
                                const newPeriods = [...periods];
                                newPeriods[index].endDate = newValue;
                                setPeriods(newPeriods);
                            }}
                        />
                    </div>
                </div>
            ))}
            <LinkButton
                type="button" onClick={addPeriod}
                text={'+ Ajouter une période'}
            />
            <br/>
            <br/>
            <button className={`btn btn-primary medium-rounded order-lg-0`} type="button"
                    onClick={calculateTotalMonths}>
                Calculer
            </button>

            {duplicateStartDateWarning && (
                <div className="alert alert-danger mt-3 col-lg-8 offset-lg-2" role="alert">
                    <p>Attention, les dates de début saisies sont identiques</p>
                </div>
            )}

            <BulleAide className={'col-12 mt-4'} text={`La période d'assurance calculée est de : <b>${totalMonths} mois${totalDays > 0 ? ` et ${totalDays} jours` : ''}</b>${dateWarning ? `<br/><i>${dateWarning}</i>` : ''}`} />
            <hr/>
        </div>
    );
};

export default Calculette;
