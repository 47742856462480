import {QuestionLabel, SimpleTitle} from '@amo/core/components/containers';
import {Autocomplete, CAPAdresseTrottinetteVae, Checkbox, PostalCodeCity, Text} from '@amo/core/components/forms';
import {
    normalizeDecimal,
    normalizeNumMarquage,
    normalizeTel,
} from '@amo/core/utils/normalize';
import {getAddress, getRaisonSocialeBySiren} from '@amo/core/utils/requestsApi';
import {
    excludeCodePostal,
    minLentghImmatriculation,
    onError,
    required,
    validateEmail, validateImmatriculation,
    validatePhone,
} from '@amo/core/utils/validateField';
import {listFormeJuridique} from '@amo/core/utils/valuesSelect.js';
import _ from 'lodash';
import {useEffect, useRef, useState} from 'react';
import {Field} from 'redux-form';
import PopupDevisContainer from '../PopupDevis/PopupDevisContainer';
import PersonneFields from './PersonneFields/PersonneFields';

const InformationsConducteur = (props) => {
    const {
        data,
        email,
        emailValidation,
        emailOnError,
        saveDevisError,
        changeValue,
        postalCodeCity,
        conducteurEstSouscripteur,
        titulaireCarteGrise,
        VehiculeImmatricule,
        permis,
        valid,
        handleSubmit,
        saveDevisLoading,
        fillLoading,
        devisOrSubscribe,
        previousStep,
        postSaveDevis,
        immatriculationAlreadySet,
        searchBy
    } = props;

    const [addressSelected, setAddressSelected] = useState(postalCodeCity);
    const [showPopupDevis, setShowPopupDevis] = useState(false);
    const [hasSearchAddress, setHasSearchAddress] = useState(false);
    const [isFirstFetch, setIsFirstFetch] = useState(true);
    const [loadingSIREN, setLoadingSIREN] = useState(false);
    const [errorSIREN, setErrorSIREN] = useState(false);
    const [needManualReason, setNeedManualReason] = useState(false);
    const inputSIREN = useRef(null);
    const [immatDisabled, setImmatDisabled] = useState(immatriculationAlreadySet && searchBy === "immatriculation");

    useEffect(() => {
        changeValue('emailOnError', email && emailValidation && email !== emailValidation ? 'onError' : null);
    }, [email, emailValidation]);

    const postSaveDevisAndShowModal = (form) => {

        setShowPopupDevis(true);
        postSaveDevis(form);
        if (hasSearchAddress) {
            getAddress(_.get(form, 'DemandeTarif[ListePersonnes][0][Adresse1]'), 'ValidateForm');
        }
    };

    const fetchGetSociety = async (value) => {
        setLoadingSIREN(true);

        try {
            let society = await getRaisonSocialeBySiren(value);
            changeValue('DemandeTarif[ListePersonnes][1][Nom]', society.denomination);

            const adresse = society.adresse;

            changeValue(
                'DemandeTarif[ListePersonnes][0][Adresse1]',
                (adresse?.numVoie + ' ' + adresse?.typeVoie + ' ' + adresse?.voie).replace('undefined', '').trim(),
            );
            changeValue('DemandeTarif[ListePersonnes][0][Adresse2]', '');
            changeValue('DemandeTarif[ListePersonnes][0][Adresse3]', '');
            changeValue('DemandeTarif[ListePersonnes][0][CP]', adresse.codePostal);
            changeValue('DemandeTarif[ListePersonnes][0][Ville]', adresse.commune);

            setAddressSelected(true);
            setErrorSIREN(false);
        } catch (e) {
            if (e.response.status === 500) {
                setNeedManualReason(true);
            }
            setErrorSIREN(e.response.data.message);
        }

        setIsFirstFetch(false);
        setLoadingSIREN(false);
    };

    const whenSIRENChange = (e) => {
        changeValue('DemandeTarif[ListePersonnes][1][Nom]', '');
        // adresse
        changeValue('DemandeTarif[ListePersonnes][0][Adresse1]', '');
        changeValue('DemandeTarif[ListePersonnes][0][Adresse2]', '');
        changeValue('DemandeTarif[ListePersonnes][0][Adresse3]', '');
        changeValue('DemandeTarif[ListePersonnes][0][CP]', '');
        changeValue('DemandeTarif[ListePersonnes][0][Ville]', '');

        setAddressSelected(null);
        setIsFirstFetch(true);
        changeValue('DemandeTarif[ListePersonnes][1][IdSociety]', e.target.value);
    };

    return (
        <div>
            <SimpleTitle>Vos informations</SimpleTitle>

            <QuestionLabel>Vos coordonnées</QuestionLabel>

            {titulaireCarteGrise && titulaireCarteGrise === '2' ? (
                <>
                    <QuestionLabel className={'f-14 mt-4'} required>
                        Indiquez votre numéro de SIREN&nbsp;
                    </QuestionLabel>
                    <div className="row">
                        <div className={'col-12 col-md-6 mt-2 mt-md-2'}>
                            <Field
                                component={Text}
                                name={'DemandeTarif[ListePersonnes][1][IdSociety]'}
                                normalize={normalizeDecimal}
                                onChange={whenSIRENChange}
                                ref={inputSIREN}
                                required
                            />

                            {errorSIREN && <div className={'alert-danger alert mb-0'}>{errorSIREN}</div>}

                            {needManualReason && (
                                <div className={'alert-danger alert mb-0 f-12'}>
                                    Nous n’arrivons pas à récupérer les informations de votre société depuis l’INPI.
                                    Merci de compléter les informations suivantes manuellement.
                                </div>
                            )}

                            {!errorSIREN && !isFirstFetch && (
                                <div className={'alert-success alert mb-0 f-12'}>
                                    Les données de la société ont bien été récupérées depuis l’INPI. Merci de vérifier
                                    les informations pré-remplies. Si les données ne correspondent pas à votre société,
                                    vérifier le numéro de SIREN renseigné et recommencer
                                </div>
                            )}

                            <span
                                className={'btn btn-primary mt-3 w-100'}
                                onClick={loadingSIREN ? null : () => fetchGetSociety(inputSIREN.current.value)}
                            >
                                {loadingSIREN ? (
                                    <div className="spinner-border" role="status" style={{width: 19, height: 19}}>
                                        <span className="sr-only"/>
                                    </div>
                                ) : (
                                    'Je vérifie le SIREN'
                                )}
                            </span>
                        </div>
                    </div>

                    <QuestionLabel className={'f-14 mt-4'} required>
                        Quelle est la raison sociale de votre société&nbsp;?&nbsp;
                    </QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-2 mt-md-2'}>
                            <Field
                                component={Text}
                                name={`DemandeTarif[ListePersonnes][1][Nom]`}
                                validate={required}
                                // disabled={!needManualReason}
                            />
                        </div>
                    </div>

                    <QuestionLabel className={'f-14 mt-4'} required>
                        Quel est le statut juridique de votre entreprise&nbsp;?&nbsp;
                    </QuestionLabel>
                    <div className="row">
                        <div className={'col-12 col-md-6 mt-2 mt-md-2'}>
                            <Field
                                name={'DemandeTarif[ListePersonnes][1][LegalStatus]'}
                                component={Autocomplete}
                                data={listFormeJuridique.map((item) => ({label: item.name, value: item.id}))}
                                required
                            />
                        </div>
                    </div>
                </>
            ) : conducteurEstSouscripteur === 'false' ? (
                <PersonneFields personneIndex={1} data={data}/>
            ) : (
                <PersonneFields personneIndex={0} data={data}/>
            )}

            <QuestionLabel className={'f-14 mt-4'}>
                Ville / Code postal<sup className={'text-danger'}>*</sup>
            </QuestionLabel>
            <div className={'row'}>
                <div
                    className={'col-12 col-md-6 mt-2 mt-md-2'}
                    data-name="DemandeTarif[ListePersonnes][0][Ville]"
                >
                    <PostalCodeCity
                        icon={'pin'}
                        id={'coordonnees'}
                        nameVille={'DemandeTarif[ListePersonnes][0][Ville]'}
                        changeValue={changeValue}
                        nameCodePostal={'DemandeTarif[ListePersonnes][0][CP]'}
                        validate={[excludeCodePostal]}
                        defaultValue={postalCodeCity}
                        placeholder={'Ex: 75011 PARIS'}
                        required
                    />
                </div>
            </div>

            <CAPAdresseTrottinetteVae font={14} changeValue={changeValue} data={data} setHasSearchAddress={setHasSearchAddress} />

            <QuestionLabel className={'f-14 mt-4'} required>
                Pouvez-vous nous indiquer votre N° de téléphone&nbsp;
            </QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-2 mt-md-2'}>
                    <Field
                        component={Text}
                        name={'DemandeTarif[ListePersonnes][0][TelPortable]'}
                        icon={'phone'}
                        iconSize={18}
                        validate={[required, validatePhone]}
                        normalize={normalizeTel}
                        onChange={(e) => changeValue('DemandeContrat[SignaturePhone]', normalizeTel(e.target.value))}
                    />
                </div>
            </div>
            <p className={'m-0 text-grey'}>
                <small>Il sera utilisé dans le cadre de la gestion de votre contrat</small>
            </p>

            <Field component={Text} name={'emailOnError'} type={'hidden'} validate={onError}/>

            <QuestionLabel className={'f-14 mt-4'} required>
                Pouvez-vous nous indiquer votre adresse e-mail personnelle&nbsp;
            </QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-2 mt-md-2'}>
                    <Field
                        component={Text}
                        name={'DemandeTarif[ListePersonnes][0][Email]'}
                        icon={'envelope'}
                        validate={[required, validateEmail]}
                        iconSize={18}
                        onChange={(e) => changeValue('DemandeContrat[SignatureEmail]', e.target.value)}
                    />
                </div>
            </div>

            <QuestionLabel className={'f-14 mt-4'} required>
                Pouvez-vous nous confirmer votre adresse e-mail personnelle&nbsp;
            </QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-2 mt-md-2'}>
                    <Field
                        component={Text}
                        name={'EmailValidation'}
                        icon={'envelope'}
                        validate={[required, validateEmail]}
                        iconSize={18}
                    />
                </div>
            </div>

            <p className={'m-0 text-grey'}>
                <small>
                    En nous communiquant votre adresse e-mail, vous acceptez que la gestion et l’exécution de votre
                    contrat soient réalisées par voie dématérialisée : vous pourrez y mettre un terme à tout moment et
                    sans frais en vous rendant sur votre Espace Assuré.
                </small>
            </p>

            {emailOnError && emailOnError === 'onError' && (
                <div className={'bg-danger p-4 rounded text-left mt-4 f-14'}>
                    <p className={'mb-0'}>Les deux adresses emails doivent être identiques.</p>
                </div>
            )}

            {conducteurEstSouscripteur === 'false' && (
                <>
                    <QuestionLabel>Le conducteur</QuestionLabel>
                    <PersonneFields personneIndex={0} data={data}/>
                </>
            )}

            <QuestionLabel>Informations complémentaires</QuestionLabel>
            {_.find(permis, function (p) {
                return p && p.TypePermis;
            }) !== undefined && (
                <>
                    <QuestionLabel className={'f-14 mt-4'}>Quel est le N° de permis du conducteur</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-2 mt-md-2'}>
                            <Field
                                component={Text}
                                name={'DemandeTarif[ListePersonnes][0][NumPermis]'}
                                normalize={(v) => _.trim(v.toUpperCase())}
                            />
                        </div>
                    </div>
                </>
            )}

            {VehiculeImmatricule === '0' ? (
                <>
                    <QuestionLabel className={'f-14 mt-4'}>Numéro de série</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-2 mt-md-2'}>
                            <Field
                                component={Text}
                                name={'DemandeTarif[Vehicule][VIN]'}
                                placeholder={'EX: AA 123 BB'}
                                normalize={normalizeNumMarquage}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <QuestionLabel className={'f-14 mt-4'}>Quel est le N° d'immatriculation du véhicule</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-2 mt-md-2'}>
                            <Field
                                name={`DemandeTarif[Vehicule][Immatriculation]`}
                                label={""}
                                placeholder={'EX: AA 123 BB'}
                                component={Text}
                                type={'text'}
                                typeFormat={'immat'}
                                validate={[minLentghImmatriculation, validateImmatriculation]}
                                disabled={immatDisabled}
                            />
                        </div>
                    </div>
                </>
            )}
            <div className={'row'}>
                <div className={'col-12 col-md-12 mt-2 mt-md-2'}>
                    <Field
                        name="immatriculation_check"
                        component={Checkbox}
                        type={'checkbox'}
                        format={(v) => v === '0'}
                        normalize={(v) => (v ? '0' : '1')}
                    >
                        <div className={'f-14 mx-2'}>Le véhicule n'a pas encore d'immatriculation</div>
                    </Field>
                </div>
            </div>

            <div className={'submit-row mt-5'}>
                <button
                    className={`${
                        !valid && 'disabled'
                    } btn btn-primary medium-rounded float-right mb-2 order-lg-1 text-uppercase`}
                    onClick={valid ? handleSubmit((form) => postSaveDevisAndShowModal(form)) : null}
                    data-id="btn-next-info-step"
                >
                    {saveDevisLoading || fillLoading ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                    ) : devisOrSubscribe === 'devis' ? (
                        'OBTENIR LE DEVIS'
                    ) : (
                        'Continuer'
                    )}
                </button>

                <button className={`btn btn-outline-primary medium-rounded order-lg-0`} onClick={() => previousStep()}>
                    Retour
                </button>
            </div>
            <div className="row">
                <p className={'mt-4 text-grey'}>
                    <small>Merci de vérifier si tous les champs du formulaire ont bien été saisis.</small>
                </p>
            </div>

            {devisOrSubscribe === 'devis' &&
                saveDevisError === false &&
                saveDevisLoading === false &&
                showPopupDevis && <PopupDevisContainer {...props} />}
        </div>
    );
};

export default InformationsConducteur;
