import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import Vehicule from "./Vehicule";
import formValueSelector from "redux-form/lib/formValueSelector";
import {change} from "redux-form";
import {getApporteur1Init, getToken} from "../../../../../../redux/selectors/init/init.selectors";
import {POST_DEJA_CLIENT, postDejaClient} from "../../../../../../redux/actions/app/dejaClient/dejaClient.actions";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {getSouscriptionDisabledVehicule} from "../../../../../../redux/selectors/vehicule/vehicule.selectors";
import { API_URL_WS_FORMULAIRE } from '@amo/core/constants';
import {setVehicule} from "formulaire-direct/src/redux/actions/app/vehicule/vehicule.actions.js";

const mapStateToProps = state => {
    const selector = formValueSelector('devis')
    return {
        marque: selector(state, `marqueVehicule`),
        cylindree: selector(state, `cylindreeVehicule`),
        modele: selector(state, `modeleVehicule`),
        familleProduit: selector(state, `typeVehicule`),
        connu: selector(state, `DemandeDevis[ConnuComment]`),
        apporteur1: getApporteur1Init(state),
        idDejaClient: selector(state, 'DemandeTarif[ListePersonnes][0][NumeroClient]'),
        idSRA: selector(state, 'DemandeTarif[Vehicule][IdentifiantSRA]'),
        loadingDejaClient: getLoadingEntity(state, POST_DEJA_CLIENT),
        vehiculeIsDisabled: getSouscriptionDisabledVehicule(state),
        queryDejaClient: `${API_URL_WS_FORMULAIRE}/fill/${getToken(state)}`,
        dejaClientValide: selector(state, 'DemandeTarif[ListePersonnes][0][DejaClient]') === 'true',
        dejaClientNom: selector(state, 'DemandeTarif[ListePersonnes][0][DejaClient]') === 'true' ? selector(state, 'DemandeTarif[ListePersonnes][0][Nom]') : null,
        dejaClientPrenom: selector(state, 'DemandeTarif[ListePersonnes][0][DejaClient]') === 'true' ? selector(state, 'DemandeTarif[ListePersonnes][0][Prenom]') : null,
        dejaClientBirthdate: selector(state, 'DemandeTarif[ListePersonnes][0][DejaClient]') === 'true' ? selector(state, 'DemandeTarif[ListePersonnes][0][DateNaissance]') : null,
        searchImmat: selector(state, `searchImmat`),
        searchBy: selector(state, `searchBy`)
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('devis', field, value),
    postDejaClient,
    setVehicule: data => setVehicule({data})

}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { idDejaClient, queryDejaClient } = stateProps
    const { postDejaClient } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postDejaClient: () => postDejaClient({idDejaClient, query: queryDejaClient})
    }
}

const VehiculeContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(Vehicule)

export default VehiculeContainer
