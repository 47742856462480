import {LinkButton, QuestionLabel, SimpleTitle, Modal} from '@amo/core/components/containers';
import { AddSinistre } from '@amo/core/components/group-form'
import { DateField, Radio, Text } from '@amo/core/components/forms';
import { isValid, maxDateToday, onError, required } from '@amo/core/utils/validateField';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Field, FieldArray } from 'redux-form';
import QuestionContentContainer from '../../QuestionContent/QuestionContentContainer';
import SlideContainer from "../../Slide/SlideContainer.js";
import Calculette from "./Calculette/Calculette.jsx";

const Antecedents = (props) => {
    const {
        sinistres,
        sinistre_auto,
        resiliationAssureur,
        annulationPermis,
        suspensionPermis,
        condamnationAlcoolemie,
        changeValue,
        cylindreeVehicule,
        familleProduit,
        assure_moto,
        assure_auto,
        nbMoisAssuranceMoto,
        nbMoisAssuranceAuto,
        valeurCrmMoto,
        valeurCrmAuto,
        typePermisB,
        malusAutoMoto,
        valid,
        handleSubmit,
        nextStep,
        fillLoading,
        previousStep,
        dejaClientAntecedents,
    } = props;
    const [antecedentsSur3ans, setAntecedentsSur3ans] = useState(
        !_.includes(['quad', 'verte', '50'], familleProduit) &&
            (suspensionPermis === 'true' ||
                annulationPermis === 'true' ||
                resiliationAssureur === '1' ||
                malusAutoMoto),
    );

    const clearAntecendentAnnulationPermis = () => {
        changeValue('DemandeTarif[Antecedents][DateAnnulationPermis]', '');
        clearAntecendentAnnulationPermisOrSuspensionPermis();
    };

    const clearAntecendentSuspensionPermis = () => {
        changeValue('DemandeTarif[Antecedents][DateSuspensionPermis]', '');
        changeValue('DemandeTarif[Antecedents][NbMoisSuspensionPermis]', '');

        if (annulationPermis !== 'true' && suspensionPermis !== 'true') {
            clearAntecendentAnnulationPermisOrSuspensionPermis();
        }
    };
    const clearAntecendentAnnulationPermisOrSuspensionPermis = () => {
        changeValue('DemandeTarif[Antecedents][CondamnationAlcoolemie]', 'false');
        changeValue('DemandeTarif[Antecedents][CondamnationStupefiants]', 'false');
        changeValue('DemandeTarif[Antecedents][CondamnationDelitFuite]', 'false');
        changeValue('DemandeTarif[Antecedents][CondamnationConduiteSansAssurance]', 'false');
    };
    const clearAntecendentResiliationAssureur = () => {
        changeValue('DemandeTarif[Antecedents][MotifResiliationAssureur]', '');
        changeValue('DemandeTarif[Antecedents][DateResiliationAssureur]', '');
    };
    const clearAntecendentListeSinistres = () => {
        changeValue('DemandeTarif[Antecedents][ListeSinistres]', []);
    };

    const controlAndSubmit = (submit) => {
        if (assure_moto === '0') {
            changeValue('DemandeTarif[ListePersonnes][0][ValeurCrmMoto]', '1.00');
        }

        if (assure_auto === '0') {
            changeValue('DemandeTarif[ListePersonnes][0][ValeurCrmAuto]', '1.00');
        }

        valid ? submit() : null;
    };

    useEffect(() => {
        const antecedents3ans =
            !_.includes(['quad', 'verte', '50'], familleProduit) &&
            (suspensionPermis === 'true' ||
                annulationPermis === 'true' ||
                resiliationAssureur === '1' ||
                malusAutoMoto);
        setAntecedentsSur3ans(antecedents3ans);

        if (!antecedents3ans) {
            changeValue('DemandeTarif[ListePersonnes][0][NbMoisReferenceMoto]', '24');
            changeValue('DemandeTarif[ListePersonnes][0][NbMoisReferenceAuto]', '24');

            if (nbMoisAssuranceMoto > 24) {
                changeValue('DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]', 24);
            }
            if (nbMoisAssuranceAuto > 24) {
                changeValue('DemandeTarif[ListePersonnes][0][NbMoisAssuranceAuto]', 24);
            }
        } else {
            changeValue('DemandeTarif[ListePersonnes][0][NbMoisReferenceMoto]', '36');
            changeValue('DemandeTarif[ListePersonnes][0][NbMoisReferenceAuto]', '36');
        }
    }, [suspensionPermis, annulationPermis, resiliationAssureur]);

    useEffect(() => {
        if (antecedentsSur3ans && nbMoisAssuranceMoto > 36) {
            changeValue('DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]', 36);
        }

        if (antecedentsSur3ans && nbMoisAssuranceAuto > 36) {
            changeValue('DemandeTarif[ListePersonnes][0][NbMoisAssuranceAuto]', 36);
        }

        if (!antecedentsSur3ans && nbMoisAssuranceMoto > 24) {
            changeValue('DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]', 24);
        }

        if (!antecedentsSur3ans && nbMoisAssuranceAuto > 24) {
            changeValue('DemandeTarif[ListePersonnes][0][NbMoisAssuranceAuto]', 24);
        }
    }, [nbMoisAssuranceMoto, nbMoisAssuranceAuto]);
    const [showCalculator, setShowCalculator] = useState(false);

    const handleShowCalculator = () => {
        setShowCalculator(!showCalculator);
    };

    return (
        <div>
            <SimpleTitle>Vos antécédents</SimpleTitle>

            <QuestionContentContainer name={'DemandeTarif[Antecedents][SuspensionPermis]'}>
                <QuestionLabel>Au cours de ces 3 dernières années</QuestionLabel>
                <QuestionLabel className={'f-14 mt-4'} required>
                    Votre permis a-t-il fait l'objet d'une suspension&nbsp;?&nbsp;
                </QuestionLabel>

                <div className={'row'}>
                    <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                        <Field
                            component={Radio}
                            name={'DemandeTarif[Antecedents][SuspensionPermis]'}
                            value={'true'}
                            type={'radio'}
                            label={'Oui'}
                            validate={required}
                            className={'small'}
                        />
                    </div>

                    <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                        <Field
                            component={Radio}
                            name={'DemandeTarif[Antecedents][SuspensionPermis]'}
                            value={'false'}
                            type={'radio'}
                            label={'Non'}
                            validate={required}
                            onChange={clearAntecendentSuspensionPermis}
                            className={'small'}
                        />
                    </div>
                </div>
            </QuestionContentContainer>

            {suspensionPermis === 'true' && (
                <QuestionContentContainer name={'DemandeTarif[Antecedents][DateSuspensionPermis]'}>
                    <QuestionLabel className={'f-14 mt-4'} required>
                        Indiquez la date de suspension de votre permis&nbsp;:&nbsp;
                    </QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                            <Field
                                component={DateField}
                                name={'DemandeTarif[Antecedents][DateSuspensionPermis]'}
                                label={'JJ/MM/AAAA'}
                                validate={[maxDateToday, isValid]}
                                maxDate={new Date()}
                            />
                        </div>
                    </div>
                </QuestionContentContainer>
            )}

            {suspensionPermis === 'true' && (
                <QuestionContentContainer name={'DemandeTarif[Antecedents][NbMoisSuspensionPermis]'}>
                    <QuestionLabel className={'f-14 mt-4'} required>
                        Indiquez le nombre de mois de suspension de permis&nbsp;?&nbsp;
                    </QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-8 col-md-8'}>
                            <Field
                                component={SlideContainer}
                                name={'DemandeTarif[Antecedents][NbMoisSuspensionPermis]'}
                                step={1}
                                min={0}
                                max={60}
                                validate={required}
                                maxBackground={'bg-error'}
                                minBackground={'bg-success'}
                                minColor={'text-white'}
                                maxColor={'text-white'}
                                symbole={' mois'}
                            />
                        </div>
                        <div className={'col-3 col-md-3 mt-4 mt-md-4'}>
                            <Field
                                component={Text}
                                name={'DemandeTarif[Antecedents][NbMoisSuspensionPermis]'}
                                type={'number'}
                                max={60}
                            />
                        </div>
                    </div>
                </QuestionContentContainer>
            )}

            <QuestionContentContainer name={'DemandeTarif[Antecedents][AnnulationPermis]'}>
                <QuestionLabel className={'f-14 mt-4'} required>
                    Votre permis a-t-il fait l'objet d'une annulation&nbsp;?&nbsp;
                </QuestionLabel>

                <div className={'row'}>
                    <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                        <Field
                            component={Radio}
                            name={'DemandeTarif[Antecedents][AnnulationPermis]'}
                            value={'true'}
                            type={'radio'}
                            label={'Oui'}
                            validate={required}
                            className={'small'}
                        />
                    </div>

                    <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                        <Field
                            component={Radio}
                            name={'DemandeTarif[Antecedents][AnnulationPermis]'}
                            value={'false'}
                            type={'radio'}
                            label={'Non'}
                            validate={required}
                            onChange={clearAntecendentAnnulationPermis}
                            className={'small'}
                        />
                    </div>
                </div>
            </QuestionContentContainer>

            {annulationPermis === 'true' && (
                <QuestionContentContainer name={'DemandeTarif[Antecedents][DateAnnulationPermis]'}>
                    <QuestionLabel className={'f-14 mt-4'} required>
                        Indiquez la date d'annulation de votre permis&nbsp;:&nbsp;
                    </QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                            <Field
                                component={DateField}
                                name={'DemandeTarif[Antecedents][DateAnnulationPermis]'}
                                label={'JJ/MM/AAAA'}
                                validate={[maxDateToday, isValid]}
                                maxDate={new Date()}
                            />
                        </div>
                    </div>
                </QuestionContentContainer>
            )}

            {(annulationPermis === 'true' || suspensionPermis === 'true') && (
                <>
                    <QuestionContentContainer name={'DemandeTarif[Antecedents][CondamnationAlcoolemie]'}>
                        <QuestionLabel className={'f-14 mt-4'} required>
                            Avez vous eu une annulation ou une suspension pour motif d'alcoolémie&nbsp;?&nbsp;
                        </QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[Antecedents][CondamnationAlcoolemie]'}
                                    value={'true'}
                                    type={'radio'}
                                    label={'Oui'}
                                    validate={required}
                                    className={'small'}
                                />
                            </div>

                            <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[Antecedents][CondamnationAlcoolemie]'}
                                    value={'false'}
                                    type={'radio'}
                                    label={'Non'}
                                    validate={required}
                                    onChange={() => changeValue('DemandeTarif[Antecedents][TxAlcool]', null)}
                                    className={'small'}
                                />
                            </div>
                        </div>
                    </QuestionContentContainer>

                    {condamnationAlcoolemie === 'true' && (
                        <QuestionContentContainer name={'DemandeTarif[Antecedents][TxAlcool]'}>
                            <QuestionLabel className={'f-14 mt-4'} required>
                                Quel taux en mg/L d'air expulsé&nbsp;?&nbsp;
                            </QuestionLabel>
                            <div className={'row'}>
                                <div className={'col-8 col-md-8'}>
                                    <Field
                                        component={SlideContainer}
                                        name={'DemandeTarif[Antecedents][TxAlcool]'}
                                        step={0.01}
                                        min={0}
                                        max={2}
                                        validate={required}
                                        maxBackground={'bg-error'}
                                        minBackground={'bg-success'}
                                        minColor={'text-white'}
                                        maxColor={'text-white'}
                                        symbole={' mg/L'}
                                    />
                                </div>
                                <div className={'col-3 col-md-3 mt-4 mt-md-4'}>
                                    <Field
                                        component={Text}
                                        name={'DemandeTarif[Antecedents][TxAlcool]'}
                                        type={'number'}
                                        max={2}
                                    />
                                </div>
                            </div>
                        </QuestionContentContainer>
                    )}

                    <QuestionContentContainer name={'DemandeTarif[Antecedents][CondamnationStupefiants]'}>
                        <QuestionLabel className={'f-14 mt-4'} required>
                            Avez vous eu une annulation ou une suspension pour motif de stupéfiants&nbsp;?&nbsp;
                        </QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[Antecedents][CondamnationStupefiants]'}
                                    value={'true'}
                                    type={'radio'}
                                    label={'Oui'}
                                    validate={required}
                                    className={'small'}
                                />
                            </div>

                            <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[Antecedents][CondamnationStupefiants]'}
                                    value={'false'}
                                    type={'radio'}
                                    label={'Non'}
                                    validate={required}
                                    className={'small'}
                                />
                            </div>
                        </div>
                    </QuestionContentContainer>

                    <QuestionContentContainer name={'DemandeTarif[Antecedents][CondamnationDelitFuite]'}>
                        <QuestionLabel className={'f-14 mt-4'} required>
                            Avez vous eu une annulation ou une suspension pour motif de délit de fuite / refus
                            d’obtempérer&nbsp;?&nbsp;
                        </QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[Antecedents][CondamnationDelitFuite]'}
                                    value={'true'}
                                    type={'radio'}
                                    label={'Oui'}
                                    validate={required}
                                    className={'small'}
                                />
                            </div>

                            <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[Antecedents][CondamnationDelitFuite]'}
                                    value={'false'}
                                    type={'radio'}
                                    label={'Non'}
                                    validate={required}
                                    className={'small'}
                                />
                            </div>
                        </div>
                    </QuestionContentContainer>

                    <QuestionContentContainer name={'DemandeTarif[Antecedents][CondamnationConduiteSansAssurance]'}>
                        <QuestionLabel className={'f-14 mt-4'} required>
                            Avez vous eu une annulation ou une suspension pour motif de conduite sans
                            assurance&nbsp;?&nbsp;
                        </QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[Antecedents][CondamnationConduiteSansAssurance]'}
                                    value={'true'}
                                    type={'radio'}
                                    label={'Oui'}
                                    validate={required}
                                    className={'small'}
                                />
                            </div>

                            <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[Antecedents][CondamnationConduiteSansAssurance]'}
                                    value={'false'}
                                    type={'radio'}
                                    label={'Non'}
                                    validate={required}
                                    className={'small'}
                                />
                            </div>
                        </div>
                    </QuestionContentContainer>
                </>
            )}

            <QuestionContentContainer name={'DemandeTarif[Antecedents][ResiliationAssureur]'}>
                <QuestionLabel className={'f-14 mt-4'} required>
                    Avez-vous fait l’objet d’une résiliation de contrat auprès d’un précédent assureur&nbsp;?&nbsp;
                </QuestionLabel>
                <div className={'row'}>
                    <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                        <Field
                            component={Radio}
                            name={'DemandeTarif[Antecedents][ResiliationAssureur]'}
                            value={'1'}
                            type={'radio'}
                            label={'Oui'}
                            validate={required}
                            className={'small'}
                        />
                    </div>

                    <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                        <Field
                            component={Radio}
                            name={'DemandeTarif[Antecedents][ResiliationAssureur]'}
                            value={'0'}
                            type={'radio'}
                            label={'Non'}
                            validate={required}
                            onChange={clearAntecendentResiliationAssureur}
                            className={'small'}
                        />
                    </div>
                </div>
            </QuestionContentContainer>

            {resiliationAssureur === '1' && (
                <QuestionContentContainer name={'DemandeTarif[Antecedents][MotifResiliationAssureur]'}>
                    <QuestionLabel className={'f-14 mt-4'} required>
                        Pour quel motif&nbsp;?&nbsp;
                    </QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'DemandeTarif[Antecedents][MotifResiliationAssureur]'}
                                value={'NP'}
                                type={'radio'}
                                label={'Non paiement de prime'}
                                validate={required}
                                className={'small'}
                            />
                        </div>

                        <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'DemandeTarif[Antecedents][MotifResiliationAssureur]'}
                                value={'FS'}
                                type={'radio'}
                                label={'Fréquence sinistres'}
                                validate={required}
                                className={'small'}
                            />
                        </div>

                        <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'DemandeTarif[Antecedents][MotifResiliationAssureur]'}
                                value={'FD'}
                                type={'radio'}
                                label={'Fausse déclaration'}
                                validate={required}
                                className={'small'}
                            />
                        </div>

                        <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'DemandeTarif[Antecedents][MotifResiliationAssureur]'}
                                value={'A'}
                                type={'radio'}
                                label={'Autres'}
                                validate={required}
                                className={'small'}
                            />
                        </div>
                    </div>
                </QuestionContentContainer>
            )}

            {resiliationAssureur === '1' && (
                <QuestionContentContainer name={'DemandeTarif[Antecedents][DateResiliationAssureur]'}>
                    <QuestionLabel className={'f-14 mt-4'} required>
                        A quelle date&nbsp;?&nbsp;
                    </QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                            <Field
                                component={DateField}
                                name={'DemandeTarif[Antecedents][DateResiliationAssureur]'}
                                label={'JJ/MM/AAAA'}
                                validate={[maxDateToday, isValid]}
                                maxDate={new Date()}
                            />
                        </div>
                    </div>
                </QuestionContentContainer>
            )}

            <hr style={{ margin: '50px 0px 40px 0' }} />

            {!_.includes(['quad', 'verte', '50'], familleProduit) &&
                !(
                    suspensionPermis === 'true' ||
                    annulationPermis === 'true' ||
                    resiliationAssureur === '1' ||
                    malusAutoMoto
                ) && (
                    <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][NbMoisReferenceMoto]'}>
                        <QuestionLabel className={'f-14 mt-4'}>Passer les antécedents sur 36 mois ?</QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[ListePersonnes][0][NbMoisReferenceMoto]'}
                                    value={'36'}
                                    type={'radio'}
                                    label={'Oui'}
                                    validate={required}
                                    onChange={() => {
                                        changeValue('DemandeTarif[ListePersonnes][0][NbMoisReferenceAuto]', '36');
                                        setAntecedentsSur3ans(true);
                                    }}
                                    className={'small'}
                                />
                            </div>

                            <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[ListePersonnes][0][NbMoisReferenceMoto]'}
                                    value={'24'}
                                    type={'radio'}
                                    label={'Non'}
                                    validate={required}
                                    onChange={() => {
                                        setAntecedentsSur3ans(false);
                                        changeValue('DemandeTarif[ListePersonnes][0][NbMoisReferenceAuto]', '24');
                                    }}
                                    className={'small'}
                                />
                            </div>
                        </div>
                    </QuestionContentContainer>
                )}

            <QuestionContentContainer name={'sinistre_auto'}>
                <QuestionLabel className={'mt-5'}>Vos sinistres</QuestionLabel>
                <QuestionLabel className={'f-14 mt-4'} required>
                    Avez-vous eu des sinistres auto et/ou moto au cours des {antecedentsSur3ans ? '3' : '2'} dernières
                    années&nbsp;?&nbsp;
                </QuestionLabel>
                <div className={'row'}>
                    <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                        <Field
                            component={Radio}
                            name={'sinistre_auto'}
                            value={'1'}
                            type={'radio'}
                            label={'Oui'}
                            validate={required}
                            className={'small'}
                        />
                    </div>

                    <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                        <Field
                            component={Radio}
                            name={'sinistre_auto'}
                            value={'0'}
                            type={'radio'}
                            label={'Non'}
                            validate={required}
                            onChange={clearAntecendentListeSinistres}
                            className={'small'}
                        />
                    </div>
                </div>
            </QuestionContentContainer>

            {sinistre_auto === '1' && (
                <QuestionContentContainer name={'DemandeTarif[Antecedents][ListeSinistres]'}>
                    <FieldArray
                        name={'DemandeTarif[Antecedents][ListeSinistres]'}
                        component={AddSinistre}
                        sinistres={sinistres}
                        className={'justify-content-start mt-5'}
                        col={'12'}
                        antecedentsSur3ans={antecedentsSur3ans}
                    />
                </QuestionContentContainer>
            )}
            {cylindreeVehicule > 50 && !_.includes(['quad', 'verte', '50'], familleProduit) ? (
                <QuestionContentContainer name={'assure_moto'}>
                    <QuestionLabel>
                        Votre historique d'assurance
                        <LinkButton
                            text={'Utiliser la calculette'}
                            left
                            icon={'pen'}
                            iconProps={{ fontSize: 13 }}
                            onClick={() => handleShowCalculator()}
                        />
                    </QuestionLabel>

                    {showCalculator && (
                        <div className={'row'}>
                            <div className={'col-12 mt-2'}>
                                <Calculette antecedentsSur3ans={antecedentsSur3ans} changeValue={changeValue} />
                            </div>
                        </div>
                    )}
                    <QuestionLabel className={'f-14 mt-4'} required>
                        Avez-vous été assuré en 2-roues au cours des {antecedentsSur3ans ? '3' : '2'} dernières
                        années&nbsp;?&nbsp;
                    </QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'assure_moto'}
                                value={'1'}
                                type={'radio'}
                                label={'Oui'}
                                validate={required}
                                className={'small'}
                            />
                        </div>

                        <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'assure_moto'}
                                value={'0'}
                                type={'radio'}
                                label={'Non'}
                                validate={required}
                                onChange={() => {
                                    changeValue('DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]', null);
                                }}
                                className={'small'}
                            />
                        </div>
                    </div>
                </QuestionContentContainer>
            ) : (
                ''
            )}

            {!_.includes(['quad', 'verte', '50'], familleProduit) && assure_moto === '1' && (
                <>
                    <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]'}>
                        <QuestionLabel className={'f-14 mt-4'} required>
                            Indiquez le nombre de mois assuré en 2-roues
                        </QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-8 col-md-8'}>
                                <Field
                                    component={SlideContainer}
                                    name={'DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]'}
                                    step={1}
                                    min={0}
                                    max={antecedentsSur3ans ? 36 : 24}
                                    validate={required}
                                    minBackground={'bg-error'}
                                    maxBackground={'bg-success'}
                                    maxColor={'text-white'}
                                    minColor={'text-white'}
                                    symbole={' mois'}
                                />
                            </div>
                            <div className={'col-3 col-md-3 mt-4 mt-md-4'}>
                                <Field
                                    component={Text}
                                    name={'DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]'}
                                    type={'number'}
                                    max={antecedentsSur3ans ? 36 : 24}
                                />
                            </div>

                            {dejaClientAntecedents && !_.isEmpty(dejaClientAntecedents) && (
                                <div className={'col-12 bg-error text-white border p-4 rounded text-left mt-5 f-14'}>
                                    {
                                        //Résilié
                                        dejaClientAntecedents.LastContractStatus === 6 && (
                                            <>
                                                <p className={'d-inline-block'}>
                                                    Merci de contrôler et de reprendre la valeur ci-dessous.
                                                </p>
                                                <p className={'d-inline-block m-0'}>
                                                    Le client a {dejaClientAntecedents.NbMoisAssurance} mois d'assurance
                                                    MOTO chez nous sur les 24 derniers mois.
                                                </p>
                                                {dejaClientAntecedents.VerificationNecessaire && (
                                                    <p className={'d-inline-block font-weight-bold mb-0 mt-3'}>
                                                        Pensez à redemander TOUS les antécédents du client sur les 24
                                                        derniers mois.
                                                    </p>
                                                )}
                                            </>
                                        )
                                    }
                                    {
                                        //Suspendu
                                        dejaClientAntecedents.LastContractStatus === 5 && (
                                            <p className={'d-inline-block font-weight- mb-0'}>
                                                Pensez à redemander TOUS les antécédents du client sur les 24 derniers
                                                mois.
                                            </p>
                                        )
                                    }
                                    {dejaClientAntecedents.LastContractStatus !== 5 &&
                                        dejaClientAntecedents.LastContractStatus !== 6 && (
                                            <>
                                                <p className={'d-inline-block'}>
                                                    Merci de contrôler et de reprendre la valeur ci-dessous.
                                                </p>
                                                <p className={'d-inline-block m-0'}>
                                                    Le client a {dejaClientAntecedents.NbMoisAssurance} mois d'assurance
                                                    MOTO chez nous.
                                                </p>
                                                {_.find(dejaClientAntecedents, function (d) {
                                                    return d.Type === 'quad' || d.Type === 'verte' || d.Type === '50';
                                                }) &&
                                                    dejaClientAntecedents.VerificationNecessaire && (
                                                        <p className={'d-inline-block font-weight-bold mb-0 mt-3'}>
                                                            Attention, le dernier contrat souscrit par le client n'avait
                                                            pas d'antécédents déclarés à la souscription. Pensez à
                                                            redemander TOUS les antécédents du client sur les 24
                                                            derniers mois.
                                                        </p>
                                                    )}
                                            </>
                                        )}
                                </div>
                            )}
                        </div>
                    </QuestionContentContainer>
                </>
            )}

            {!_.includes(['quad', 'verte'], familleProduit) &&
                ((assure_moto === '0' &&
                    valeurCrmMoto !== undefined &&
                    valeurCrmMoto !== '1.00' &&
                    valeurCrmMoto !== '1') ||
                    (nbMoisAssuranceMoto === undefined &&
                        valeurCrmMoto !== undefined &&
                        valeurCrmMoto !== '1.00' &&
                        valeurCrmMoto !== '1')) && (
                    <div className={'bg-warning p-4 rounded text-left mt-5 f-14'}>
                        <p className={'mb-0'}>
                            <strong>Attention !</strong> vous avez declaré un bonus de {valeurCrmMoto} sans assurance
                            moto sur les derniers mois. Sans modification de votre part, le bonus declaré passera à 1.00
                            automatiquement.
                        </p>
                    </div>
                )}

            {typePermisB && (
                <>
                    {!_.includes(['quad', 'verte', '50'], familleProduit) && (
                        <QuestionContentContainer name={'assure_auto'}>
                            <QuestionLabel className={'f-14 mt-4'} required>
                                Avez-vous été assuré en auto au cours des {antecedentsSur3ans ? '3' : '2'} dernières
                                années&nbsp;?&nbsp;&nbsp;
                            </QuestionLabel>
                            <div className={'row'}>
                                <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                    <Field
                                        component={Radio}
                                        name={'assure_auto'}
                                        value={'1'}
                                        type={'radio'}
                                        label={'Oui'}
                                        validate={required}
                                        className={'small'}
                                    />
                                </div>

                                <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                    <Field
                                        component={Radio}
                                        name={'assure_auto'}
                                        value={'0'}
                                        type={'radio'}
                                        label={'Non'}
                                        validate={required}
                                        onChange={() => {
                                            changeValue('DemandeTarif[ListePersonnes][0][NbMoisAssuranceAuto]', null);
                                        }}
                                        className={'small'}
                                    />
                                </div>
                            </div>
                        </QuestionContentContainer>
                    )}
                    {!_.includes(['quad', 'verte'], familleProduit) &&
                        ((assure_auto === '0' &&
                            valeurCrmAuto !== undefined &&
                            valeurCrmAuto !== '1.00' &&
                            valeurCrmAuto !== '1') ||
                            (nbMoisAssuranceAuto === undefined &&
                                valeurCrmAuto !== undefined &&
                                valeurCrmAuto !== '1.00' &&
                                valeurCrmAuto !== '1')) && (
                            <div className={'bg-warning p-4 rounded text-left mt-5 f-14'}>
                                <p className={'mb-0'}>
                                    <strong>Attention !</strong> vous avez declaré un bonus de {valeurCrmAuto} sans
                                    assurance auto sur les derniers mois. Sans modification de votre part, le bonus
                                    declaré passera à 1.00 automatiquement.
                                </p>
                            </div>
                        )}
                </>
            )}

            {!_.includes(['quad', 'verte', '50'], familleProduit) && assure_auto === '1' && (
                <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][NbMoisAssuranceAuto]'}>
                    <QuestionLabel className={'f-14 mt-4'} required>
                        Indiquez le nombre de mois assuré en auto
                    </QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-8 col-md-8'}>
                            <Field
                                component={SlideContainer}
                                name={'DemandeTarif[ListePersonnes][0][NbMoisAssuranceAuto]'}
                                step={1}
                                min={0}
                                max={antecedentsSur3ans ? 36 : 24}
                                validate={required}
                                minBackground={'bg-error'}
                                maxBackground={'bg-success'}
                                maxColor={'text-white'}
                                minColor={'text-white'}
                                symbole={' mois'}
                            />
                        </div>
                        <div className={'col-3 col-md-3 mt-4 mt-md-4'}>
                            <Field
                                component={Text}
                                name={'DemandeTarif[ListePersonnes][0][NbMoisAssuranceAuto]'}
                                type={'number'}
                                max={antecedentsSur3ans ? 36 : 24}
                            />
                        </div>
                    </div>
                </QuestionContentContainer>
            )}

            {familleProduit === 'quad' || familleProduit === 'verte' ? (
                <>
                    <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][ValeurCrmMoto]'}>
                        <QuestionLabel required>Avez-vous du malus&nbsp;?&nbsp;</QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[ListePersonnes][0][ValeurCrmMoto]'}
                                    value={'onError'}
                                    type={'radio'}
                                    label={'Oui'}
                                    validate={[required, onError]}
                                    className={'small'}
                                />
                            </div>

                            <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[ListePersonnes][0][ValeurCrmMoto]'}
                                    value={'1'}
                                    type={'radio'}
                                    label={'Non'}
                                    validate={[required, onError]}
                                    className={'small'}
                                />
                            </div>
                        </div>
                    </QuestionContentContainer>

                    {valeurCrmMoto === 'onError' && (
                        <div className={'bg-danger p-4 rounded text-left mt-5 f-14'}>
                            <p className={'mb-0'}>
                                Nous ne pouvons pas proposer de tarif pour les bonus supérieurs à 1.00
                            </p>
                        </div>
                    )}
                </>
            ) : (
                ''
            )}

            <div className={'submit-row mt-5'}>
                <button
                    className={`${!valid && 'disabled'} btn btn-primary float-right medium-rounded mb-2 order-lg-1`}
                    onClick={() => controlAndSubmit(handleSubmit(nextStep))}
                    data-id="btn-next"
                >
                    {fillLoading ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    ) : (
                        'Continuer'
                    )}
                </button>

                <button className={`btn btn-outline-primary medium-rounded order-lg-0`} onClick={() => previousStep()}>
                    Retour
                </button>
            </div>
            <div className="row">
                <p className={'mt-4 text-grey'}>
                    <small>Merci de vérifier si tous les champs du formulaire ont bien été saisis.</small>
                </p>
            </div>
        </div>
    );
};

Antecedents.defaultProps = {};

Antecedents.propTypes = {};

export default Antecedents;
