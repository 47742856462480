import {connect} from 'react-redux'
import Recapitulatif from "./Recapitulatif";
import {change, formValueSelector, getFormValues} from "redux-form";
import moment from 'moment'
import {
    getListeFormulesTarif,
    getUrlPDFDispositionsGeneralesTarif,
    isLoadedTarif
} from "../../../../../../redux/selectors/tarif/tarif.selectors";
import {
    getAdressSouscripteurDemandeTarif,
    getCrmAutoSouscripteurDemandeTarif,
    getCrmMotoSouscripteurDemandeTarif,
    getDateMECVehiculeDemandeTarif,
    getFormuleDevisInit,
    getImmatriculationVehiculeDemandeTarifInit,
    getNameSouscripteurDemandeTarif, getRealisateurDemandeTarifInit,
    getToken,
    getUsageVehiculeDemandeTarif,
    getVersionVehiculeDemandeTarifInit
} from '../../../../../../redux/selectors/init/init.selectors';
import {getUsageByCode} from "../../../../../../utils/transcoLabels";
import {reformatFormules} from "../Tarif/reformatFormules";
import {getLoadingEntity, getPrixTotal, getPrixTotalPromo} from "../../../../../../redux/selectors/ui/ui.selectors";
import {
    getMarqueVehicule,
    getModeleVehicule,
    getTypeVehicule
} from "../../../../../../redux/selectors/vehicule/vehicule.selectors";
import {POST_PAY, postPay} from "../../../../../../redux/actions/app/pay/pay.action";
import {postTarif, TARIF} from "../../../../../../redux/actions/app/tarif/tarif.actions";
import {compose, bindActionCreators} from "redux";
import {loader} from "@amo/core/components/loaders";
import { API_URL_WS_FORMULAIRE, APP_URL_APPLICATION } from '@amo/core/constants/constants.js';
import _ from "lodash";

const mapStateToProps = state => {
    const selector = formValueSelector('devis');
    let marque = getMarqueVehicule(state);
    const ville = selector(state, 'DemandeContrat[villeIBAN]')
    const cp = selector(state, 'DemandeContrat[cpIBAN]')
    const datenaissanceCond = selector(state, 'DemandeTarif[ListePersonnes][0][DateNaissance]')

    const mineur = datenaissanceCond && moment.isMoment(datenaissanceCond) && moment(datenaissanceCond).add(18, 'years') > moment();
    const titulaireCarteGriseSo = selector(state, 'titulaireCarteGrise') === '2';

    let nomPrenomTitulaire = selector(state, 'DemandeTarif[ListePersonnes][0][Nom]')+' '+selector(state, 'DemandeTarif[ListePersonnes][0][Prenom]');
    if(titulaireCarteGriseSo){
        nomPrenomTitulaire =  selector(state, 'DemandeTarif[ListePersonnes][1][Nom]');
    }else if(mineur){
        nomPrenomTitulaire =  selector(state, 'DemandeTarif[ListePersonnes][1][Nom]')+' '+selector(state, 'DemandeTarif[ListePersonnes][1][Prenom]');
    }

    return {
        query: `${API_URL_WS_FORMULAIRE}/pay/${getToken(state)}`,
        queryTarif: `${API_URL_WS_FORMULAIRE}/get_tarif/${getToken(state)}`,
        url_pay: {
            token: getToken(state),
            url_return: `${APP_URL_APPLICATION}/paiement`
        },
        loaded: getLoadingEntity(state, TARIF) === false,
        loading: getLoadingEntity(state, POST_PAY),
        tarifIsLoaded: isLoadedTarif(state),
        devisHamon: selector(state, 'DemandeContrat[DevisHamon]'),
        dispositionGenerale: getUrlPDFDispositionsGeneralesTarif(state),
        conducteur: getNameSouscripteurDemandeTarif(state),
        addressConducteur: getAdressSouscripteurDemandeTarif(state),
        crmAuto: getCrmAutoSouscripteurDemandeTarif(state),
        neConnaitPasNumeroContratActuel: selector(state, 'dont-know-contract-number'),
        crmMoto: getCrmMotoSouscripteurDemandeTarif(state),
        immatriculation: getImmatriculationVehiculeDemandeTarifInit(state),
        immatriculationAlreadySet: selector(state, 'DemandeTarif[Vehicule][Immatriculation]'),
        searchBy: selector(state, `searchBy`),
        VehiculeImmatricule: selector(state, 'immatriculation_check'),
        marque: marque,
        modele: getModeleVehicule(state),
        version: getVersionVehiculeDemandeTarifInit(state),
        dateMec: moment(getDateMECVehiculeDemandeTarif(state), 'DD/MM/YYYY').format('L'),
        usage: getUsageByCode(getUsageVehiculeDemandeTarif(state)),
        formule: getFormuleDevisInit(state) ? reformatFormules([ getFormuleDevisInit(state) ], getTypeVehicule(state))[0] : null,
        prixTotal: selector(state, `DemandeTarif[Police][FractionnementChoisi]`) ? getPrixTotal(state) : 0,
        prixTotalPromo: selector(state, `DemandeTarif[Police][FractionnementChoisi]`) ? getPrixTotalPromo(state) : 0,
        fractionnement: selector(state, `DemandeTarif[Police][FractionnementChoisi]`),
        prelevement_auto: selector(state, `prelevement_auto`),
        postalCodeCity: ville ? `${ville} ${cp}` : '',
        permis: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis]'),
        nomPrenom: nomPrenomTitulaire,
        nomTitulaire: selector(state, 'DemandeContrat[NomTitulaire]'),
        adresseIban: selector(state, 'DemandeTarif[ListePersonnes][0][Adresse1]'),
        cpIban: selector(state, 'DemandeTarif[ListePersonnes][0][CP]'),
        villeIban: selector(state, 'DemandeTarif[ListePersonnes][0][Ville]'),
        ibanIsCheck: Boolean(selector(state, 'DemandeContrat[BIC]')),
        dateHeureEffet: selector(state, 'DemandeContrat[DateHeureEffet]'),
        formuleChoisi: _.find(getListeFormulesTarif(state), ['CodeFormule', selector(state, 'DemandeTarif.Police.FormuleChoisie]')]),
        values: getFormValues('devis')(state),
        nbMoisAssuranceMoto: selector(state, 'DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]'),
        typeVehicule: getTypeVehicule(state),
        idRealisateur: getRealisateurDemandeTarifInit(state),
        souscripteurIsSociety:
            (selector(state, 'DemandeTarif[ListePersonnes][1][TypePersonne]') === 'M' &&
                selector(state, 'DemandeTarif[ListePersonnes][1][Civilite]') === '3') ||
            false,
    }
};

const mapDispatchToProps = dispatch => bindActionCreators({
    postPay,
    postTarif,
    changeValue: (field, value) => change('devis', field, value)
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, queryTarif, url_pay, tarifIsLoaded, values } = stateProps
    const { postPay, postTarif } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postPaySubmit: (form) =>  postPay({query, form, url_pay}),
        load: () => !tarifIsLoaded && postTarif({form: values, query: queryTarif})
    }
}

const RecapitulatifContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Recapitulatif)

export default RecapitulatifContainer
