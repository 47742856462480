import {connect} from 'react-redux'
import Footer from "./Footer";
import {getGestionnaireInit} from "../../../../../redux/selectors/init/init.selectors";

const mapStateToProps = state => {
    return {
        gestionnaireIsAMO: getGestionnaireInit(state).codeBelair === 43397
    }
}

const FooterContainer = connect(mapStateToProps)(Footer)

export default FooterContainer
