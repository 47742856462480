import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {change, formValueSelector} from "redux-form";
import InformationsVehicule from "./InformationsVehicule";
import { getLinks } from '../../../../../../redux/selectors/init/init.selectors.js';

const mapStateToProps = state => {
    const selector = formValueSelector('devis')
    const ville = selector(state, 'DemandeTarif[Vehicule][VilleGarage]')
    const cp = selector(state, 'DemandeTarif[Vehicule][CodePostalGarage]')

    return {
        postalCodeCity: ville ? `${ville} ${cp}` : '',
        possessionPlusDe3Mois: selector(state, 'DemandeTarif[Vehicule][PossessionPlusDe3Mois]'),
        carteGriseFrancaise: selector(state, 'DemandeTarif[Vehicule][CarteGriseFrancaise]'),
        familleProduit: selector(state, 'typeVehicule'),
        vehiculeImmatricule: selector(state, 'DemandeTarif[Vehicule][VehiculeImmatricule]'),
        cylindree: selector(state, `cylindreeVehicule`),
        usageNonLivraison: selector(state, `DemandeTarif[Vehicule][UsageNonLivraison]`),
        dateMEC: selector(state, `DemandeTarif[Vehicule][DateMEC]`),
        dateAchat: selector(state, 'DemandeTarif[Vehicule][DateAchatVehicule]'),
        situationVehicule: selector(state, `DemandeTarif[Vehicule][SituationVehicule]`),
        usage: selector(state, `DemandeTarif[Vehicule][UsageVehicule]`),
        assure_3mois: selector(state, `DemandeTarif[Vehicule][Assure3DerniersMois]`),
        links: getLinks(state),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('devis', field, value)
}, dispatch)

const InformationsVehiculeContainer = connect(mapStateToProps, mapDispatchToProps)(InformationsVehicule)

export default InformationsVehiculeContainer
