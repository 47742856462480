import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {change, formValueSelector, getFormValues} from "redux-form";
import InformationsConducteur from "./InformationsConducteur";
import {
    getPersonnePrincipaleIsSouscripteurDemandeTarifInit
} from "../../../../../../redux/selectors/init/init.selectors";
import _ from "lodash";
import {getDevisOrSubscribe} from "../../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = state => {
    const selector = formValueSelector('devis')
    const ville = selector(state, 'DemandeTarif[ListePersonnes][0][Ville]')
    const cp = selector(state, 'DemandeTarif[ListePersonnes][0][CP]')
    const ListePersonnes = selector(state, 'DemandeTarif[ListePersonnes]')
    const conducteurPrincipal = ListePersonnes ? _.find(ListePersonnes, {'TypePersonne': 'P', 'RoleConducteur': 'P'}) : {}

    return {
        data: getFormValues('devis')(state),
        postalCodeCity: ville ? `${ville} ${cp}` : null,
        conducteurEstSouscripteur: conducteurPrincipal ? String(conducteurPrincipal.Souscripteur) : getPersonnePrincipaleIsSouscripteurDemandeTarifInit(state),
        titulaireCarteGrise: selector(state, 'titulaireCarteGrise'),
        VehiculeImmatricule: selector(state, 'immatriculation_check'),
        permis: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis]'),
        addressNotFound: selector(state, 'addressNotFound'),
        devisOrSubscribe: getDevisOrSubscribe(state),
        email: selector(state, 'DemandeTarif[ListePersonnes][0][Email]'),
        emailValidation: selector(state, 'EmailValidation'),
        emailOnError: selector(state, 'emailOnError'),
        immatriculationAlreadySet: selector(state, 'DemandeTarif[Vehicule][Immatriculation]'),
        searchBy: selector(state, `searchBy`)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('devis', field, value)
}, dispatch)


const InformationsConducteurContainer = connect(mapStateToProps, mapDispatchToProps)(InformationsConducteur)

export default InformationsConducteurContainer
